<template>
  <el-main>
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>任务详情</span>
          </div>
          <el-row>
            <el-col :span="8">
              <el-row>
                <el-col :span="12">任务ID</el-col>
                <el-col :span="12">{{ job.id }}</el-col>
              </el-row>
              <el-row class="mt12">
                <el-col :span="12">任务状态</el-col>
                <el-col :span="12">{{ job.status_cn }}</el-col>
              </el-row>
              <el-row class="mt12">
                <el-col :span="12">平台</el-col>
                <el-col :span="12">{{ job.platform_name }}</el-col>
              </el-row>
              <el-row class="mt12">
                <el-col :span="12">任务类型</el-col>
                <el-col :span="12">{{ job.type_cn }}</el-col>
              </el-row>
              <el-row class="mt12">
                <el-col :span="12">备注</el-col>
                <el-col :span="12">{{ job.remark }}</el-col>
              </el-row>
              <el-row class="mt12">
                <el-col :span="12">预计等待时间</el-col>
                <el-col :span="12">{{ job.estimated_queue_time_cn }}</el-col>
              </el-row>
              <el-row class="mt12">
                <el-col :span="12">创建时间</el-col>
                <el-col :span="12">{{ job.insert_time }}</el-col>
              </el-row>
              <el-row class="mt12">
                <el-col :span="12">完成时间</el-col>
                <el-col :span="12">{{ job.finish_time }}</el-col>
              </el-row>
              <el-row class="mt12">
                <el-col :span="12">部门</el-col>
                <el-col :span="12">{{ job.department_name }}</el-col>
              </el-row>
              <el-row class="mt12">
                <el-col :span="12">创建人</el-col>
                <el-col :span="12">{{ job.creator_name }}</el-col>
              </el-row>
              <el-row class="mt12">
                <el-button v-if="job.status==100" type="primary" @click="exportJob">
                  导出全部原始数据
                </el-button>
              </el-row>
              <el-row class="mt12">
                <el-button v-if="job.status==100" type="primary" @click="exportWordCloud">
                  导出全部词频数据
                </el-button>
              </el-row>
            </el-col>
            <el-col :span="16">
              <el-table
                  :data="tableData"
                  style="width: 100%">
                <el-table-column
                    prop="status_cn"
                    label="状态"
                >
                </el-table-column>
                <el-table-column v-if="showKeyword"
                                 prop="keyword"
                                 label="关键词"
                >
                </el-table-column>
                <el-table-column v-if="showUrl"
                                 prop="url"
                                 label="链接"
                >
                  <template slot-scope="scope">
                    <a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="count"
                    label="数据量">
                </el-table-column>
                <el-table-column
                    prop="finish_time"
                    label="完成时间">
                </el-table-column>
                <el-table-column
                    label="取消" align="right">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.status==1" type="primary" size="mini" @click="cancel(scope)">
                      取消
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column v-if="showFailBtn"
                    label="标记失败" align="right">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.status==2" type="primary" size="mini" @click="fail(scope)">
                      标记失败
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column v-if="showFailBtn"
                                 label="退还额度" align="right">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.status!=99"  type="primary" size="mini" @click="setCanceled(scope)">
                    退还额度
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                    label="下载excel" align="right">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.status==100" type="primary" size="mini" @click="download(scope)">
                      下载excel
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                    label="查看词云" align="right">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.status==100" type="primary" size="mini" @click="viewWordCloud(scope)">
                      查看词云
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="12">
              <p>如果数据量为0，可能任务执行失败，请重新创建任务</p>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  name: "TaskDetail",
  created() {
    let me = this;
    let role = localStorage.getItem("role");
    if (role == 99 || role == 98) {
      me.showFailBtn = true;
    }
    me.id = this.$route.query.id;
    Promise.all([me.axios.get('/api/platform/list')
      , me.axios.get('/api/department/list')
      , me.axios.get('/api/user/list')])
        .then(([platforms, departments, users]) => {
          platforms.forEach(platform => {
            me.map.platform['id_' + platform.id] = platform.name;
          })
          departments.forEach(department => {
            me.map.department['id_' + department.id] = department.name;
          })
          users.forEach(user => {
            me.map.user['id_' + user.id] = user.name;
          })
          me.getDetail();
          me.threadId = setInterval(me.getDetail, 5000);
        })
  },
  beforeDestroy() {
    clearInterval(this.threadId);
  },
  data: function () {
    return {
      id: null,
      map: {
        user: {},
        platform: {},
        department: {},
        type: {
          'id_1': '关键词',
          'id_2': '评论'
        },
        status: {
          'id_1': '排队中',
          'id_2': '执行中',
          'id_3': '计算词频',
          'id_98': '失败',
          'id_99': '已取消',
          'id_100': '已完成'
        }
      },
      rawData: {},
      showKeyword: false,
      showUrl: false,
      threadId: null,
      showFailBtn: false
    }
  },
  computed: {
    job() {
      let me = this;
      let job = {};
      job['id'] = me.rawData['id'];
      job['status'] = me.rawData['status'];
      job['status_cn'] = me.map.status['id_' + me.rawData['status']];
      job['platform_name'] = me.map.platform['id_' + me.rawData['platform_id']];
      job['type_cn'] = me.map.type['id_' + me.rawData['type']];
      job['remark']=me.rawData['remark'];
      job['creator_name'] = me.map.user['id_' + me.rawData['creator_id']];
      job['department_name'] = me.map.department['id_' + me.rawData['department_id']];
      job['insert_time'] = me.rawData['insert_time'] ? me.rawData['insert_time'] : '-';
      job['finish_time'] = me.rawData['finish_time'] ? me.rawData['finish_time'] : '-';
      job['estimated_queue_time_cn'] = me.formatTime(me.rawData['estimated_queue_time']);
      if ((me.rawData['status'] == 1 || me.rawData['status'] == 2) && me.rawData['estimated_queue_time'] == null) {
        job['estimated_queue_time_cn'] = "计算中...";
      }
      return job;
    },
    tableData() {
      let me = this;
      let tasks = me.rawData['tasks'];
      if (!tasks) {
        return [];
      }
      return tasks.map(val => {
        val['status_cn'] = me.map.status['id_' + val['status']];
        val['finish_time'] = val['finish_time'] ? val['finish_time'] : '-';
        return val;
      });
    }
  },
  methods: {
    getDetail() {
      let me = this;
      me.axios.get('/api/job/get', {params: {id: me.id}}).then(res => {
        me.rawData = res;
        if (res['type']) {
          if (res['type'] == 1) {
            me.showKeyword = true;
            me.showUrl = false;
          } else if (res['type'] == 2) {
            me.showKeyword = false;
            me.showUrl = true;
          }
        }
      })
    },
    download(scope) {
      let me = this;
      window.open(me.const.baseURL + '/api/task/export?task_id=' + scope.row.id + '&token=' + localStorage.getItem('token'), '_blank');
    },
    viewWordCloud(scope) {
      this.$router.push('/home/word-cloud?task_id=' + scope.row.id);
    },
    cancel(scope) {
      let me = this;
      me.axios.post('/api/task/cancel', {id: scope.row.id}).then(() => {
        me.getDetail();
      })
    },
    fail(scope){
      let me = this;
      me.axios.post('/api/task/fail', {id: scope.row.id}).then(() => {
        me.getDetail();
      })
    },
    setCanceled(scope){
      let me = this;
      me.axios.post('/api/task/setCanceled', {id: scope.row.id}).then(() => {
        me.getDetail();
      })
    },
    exportJob() {
      let me = this;
      window.open(me.const.baseURL + '/api/job/exportData?id=' + me.id + '&token=' + localStorage.getItem('token'), '_blank');

    },
    exportWordCloud() {
      let me = this;
      window.open(me.const.baseURL + '/api/job/exportWordCloud?id=' + me.id + '&token=' + localStorage.getItem('token'), '_blank');
    }
  }
}
</script>

<style scoped>
.mt12 {
  margin-top: 12px
}
</style>